var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShow),expression:"shouldShow"}],staticClass:"ui-form-field",class:_vm.customClass},[_c('div',{staticClass:"ui-form-field__label-container"},[_c('div',{staticClass:"ui-form-field__label"},[(_vm.data.label)?_c('div',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.data.label)}}):_vm._e(),_vm._v(" "),(_vm.data.isRequired)?_c('div',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),(_vm.data.tooltip && _vm.data.tooltip.innerHTML)?_c('bd-tooltip',{attrs:{"innerHTML":_vm.data.tooltip.innerHTML,"innerHeading":_vm.data.tooltip.innerHeading,"label":_vm.data.tooltip.label,"tooltipWidth":_vm.data.tooltip.tooltipWidth,"position":_vm.data.tooltip.position,"iconSize":_vm.data.tooltip.iconSize,"tooltipBackground":_vm.data.tooltip.tooltipBackground,"iconPosition":_vm.data.tooltip.iconPosition}}):_vm._e()],1),_vm._v(" "),_c('validation-provider',{attrs:{"vid":'validate_' + _vm.fieldId,"rules":_vm.rules,"customMessages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ui-form-field__input-wrapper",class:errors.length > 0 ? 'has-error' : ' ' + _vm.data.size},[_c('div',{staticClass:"inner-wrapper"},[(_vm.data.placeholder && _vm.data.placeholder.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isClean),expression:"isClean"}],staticClass:"ui-form-field__placeholder",domProps:{"textContent":_vm._s(_vm.data.placeholder)}}):_vm._e(),_vm._v(" "),((_vm.data.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"ui-form-field__input",class:{
            'has-error': errors.length > 0,
          },attrs:{"name":_vm.data.label,"minlength":_vm.data.minLength ? _vm.data.minLength : undefined,"maxLength":_vm.data.maxLength ? _vm.data.maxLength : undefined,"disabled":_vm.data.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.input)?_vm._i(_vm.input,null)>-1:(_vm.input)},on:{"focus":function($event){return _vm.focusUpdated(errors)},"input":function($event){[_vm.inputUpdated(errors), _vm.delayed(errors)]},"change":[function($event){var $$a=_vm.input,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.input=$$a.concat([$$v]))}else{$$i>-1&&(_vm.input=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.input=$$c}},function($event){[_vm.detailsUpdated(errors), _vm.delayed(errors)]}]}}):((_vm.data.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"ui-form-field__input",class:{
            'has-error': errors.length > 0,
          },attrs:{"name":_vm.data.label,"minlength":_vm.data.minLength ? _vm.data.minLength : undefined,"maxLength":_vm.data.maxLength ? _vm.data.maxLength : undefined,"disabled":_vm.data.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.input,null)},on:{"focus":function($event){return _vm.focusUpdated(errors)},"input":function($event){[_vm.inputUpdated(errors), _vm.delayed(errors)]},"change":[function($event){_vm.input=null},function($event){[_vm.detailsUpdated(errors), _vm.delayed(errors)]}]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"ui-form-field__input",class:{
            'has-error': errors.length > 0,
          },attrs:{"name":_vm.data.label,"minlength":_vm.data.minLength ? _vm.data.minLength : undefined,"maxLength":_vm.data.maxLength ? _vm.data.maxLength : undefined,"disabled":_vm.data.disabled,"type":_vm.data.type},domProps:{"value":(_vm.input)},on:{"focus":function($event){return _vm.focusUpdated(errors)},"input":[function($event){if($event.target.composing){ return; }_vm.input=$event.target.value},function($event){[_vm.inputUpdated(errors), _vm.delayed(errors)]}],"change":function($event){[_vm.detailsUpdated(errors), _vm.delayed(errors)]}}})]),_vm._v(" "),(_vm.charLimit)?_c('div',{staticClass:"ui-form-field__char-limit",domProps:{"textContent":_vm._s(_vm.charLimit)}}):_vm._e(),_vm._v(" "),(_vm.data.description)?_c('div',{staticClass:"ui-form-field__instruction",class:{
          top: _vm.data.descriptionPlacement === 'top',
          bottom: _vm.data.descriptionPlacement === 'bottom',
        }},[_c('span',{domProps:{"textContent":_vm._s(_vm.data.description)}})]):_vm._e(),_vm._v(" "),(errors[0])?_c('span',{staticClass:"ui-form-field__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._v(" "),(_vm.data.type === 'email' && _vm.emailError && !errors[0])?_c('span',{staticClass:"ui-form-field__error"},[_vm._v("\n        Please enter a valid email address\n      ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }