//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      cmsSettings: 'settings/cms',
    }),
    subscriptionImageId() {
      if (this.cmsSettings?.footer?.subscription_image) {
        return this.cmsSettings.footer.subscription_image.toString();
      }

      return '';
    },
    hasFooterData() {
      return this.cmsSettings?.footer ?? false;
    },
    hasSubscriptionWrapper() {
      return this.cmsSettings?.footer?.subscription_wrapper ?? false;
    },
    hasSubscriptionForm() {
      return this.cmsSettings?.footer?.subscription_form ?? false;
    },
  },
  methods: {
    hasSubscriptionImage() {
      return this.cmsSettings?.footer?.subscription_image ?? false;
    },
  },
};
