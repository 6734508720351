//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '~/util/helper';
import FormMixins from '~/mixins/form';
import CalculateConditionalLogicMixins from '~/mixins/calculateConditionalLogic';

export default {
  mixins: [FormMixins, CalculateConditionalLogicMixins],
  props: {
    sub: {
      default: false,
      type: Boolean,
    },
    resetInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    delayed() {
      return debounce(this.delayUpdated, 250);
    },
  },
  watch: {
    // Watch for changes in the 'defaultValue' prop and re-validate email
    'data.defaultValue'(value) {
      this.input = value;
      this.validateEmail(value); // Revalidate on defaultValue change
    },
  },
  methods: {
    // Regex pattern to match valid emails (with TLDs max 4 chars and optional 2nd-level domain)
    isValidEmail(email) {
      if (!email) return true; // Allow empty values

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const result = emailRegex.test(email);
      return result;
    },

    // Validate the email and update emailError state directly
    validateEmail(email) {
      if (this.data.type === 'email') {
        // Validate email and update the error state directly
        this.emailError = !this.isValidEmail(email);
      }
    },

    // Emit the updated value and validate the email every time input is updated
    inputUpdated(errors) {
      this.$emit('inputUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
      this.validateEmail(this.input); // Validate email every time input is updated
    },

    detailsUpdated(errors) {
      this.$emit('detailsUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },

    focusUpdated(errors) {
      this.$emit('focusUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },

    delayUpdated(errors) {
      this.$emit('delayUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: errors,
      });
    },
  },
  mounted() {
    // If a default value is provided, initialize the input and validate the email
    if (this.data.defaultValue) {
      this.input = this.data.defaultValue;
      this.$emit('inputUpdated', {
        fieldName: this.data.fieldName,
        value: this.input,
        errors: this.errors,
      });
      this.validateEmail(this.data.defaultValue); // Validate initially
    } else {
      this.input = '';
    }

    // Reset the input value if resetInput is true
    if (this.resetInput) {
      this.input = '';
    }
  },
};
